import { PageLayout } from "@components";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import { JoinInstitutionsV2 } from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import { Demo, GetWorkDone } from ".././biopharmaceutical";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function AcademiaV4() {
	const demoProps = {
		title: "LIMS designed for academic research labs",
		subtitle:
			"We bring your team’s work together in one shared space. Improve process management by leaving the repetitive stuff to us so you can do more of the work you do best.",
		srcImg: "man-do-sample-test-in-lab.png",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(styles.getADemoOverride, styles.clinicalGetADemoOverride),
        titleStylesOverride: styles.academiaDemoTitleStylesOverride,
		subtitleStylesOverride: styles.academiaDemoSubtitleStylesOverride,
		leftSectionOverride: styles.academiaDemoLeftSectionOverride

	};

	const cards: FeatureCardProps[] = [
		{
			title: "Modern R&D platform where your entire department is aboard",
			list: [
				"Site-wide discounts for departments and cores",
				"Finally, an ELN built for scientists",
				"Single source of truth for your inventory",
			],
            sublist:[
                'Ask your department if they offer Genemod as a preferred software vendor.',
                'It’s never been easier to cycle across all your lab data in one place.',
                'From reagents to custom item types - we customize to match exactly how it is in the lab.',
            ],
			image: (
				<StaticImage
					src={
						"../../../assets/images/landing_page/products_inventory.png"
					}
					alt="Easily Manage and Submit Orders"
				/>
			),
			className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
		{
			title: "Most affordable paid plans you’ll find",
            subtitle:"The same cost as a latte a week",
			list: [
				"Fully customizable – shelves to racks and cryoboxes",
				"Inventory alerts for 100+ use cases",
				"Genemod Notebook takes R&D collaboration to a whole other level",
			],
			sublist: [
				"Custom design a virtual freezer that matches its real-life counterpart, shelf for shelf and item for item.",
				"Create alerts for when consumables reach a certain stock level or calculate inventory cost and fluctuation.",
				"Create flexible and editable protocol templates, add attachments to experiments, and build Excel-like tables within entries.",
			],
			image: (
				<StaticImage
					src={"../../../assets/solutions/shelf-and-rack.png"}
					alt="Shelf and rack"
				/>
			),
			orientation: "right",
			className: styles.featureQuickAccess,
            sublistColor:'text-helper',
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
	];

	const getWorkDoneProps = {
		title: "Where research teams get work done",
		subtitle:
			"We’ve built the first platform for life sciences R&D where project management meets electronic lab notebook.",
		showSquares: false,
		showCentralizedPlatformV2: true,
		showSubtitleBottom: true,
		titleWidth: 920,
		applyV4Styles: true,
	};
	const footerContent = {
		headerText: "Powered by Genemod",
		subheaderText:
			"Genemod enables large institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is.",
	};
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "research" }}>
			<Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
			<Margins className={styles.getWorkDoneMargins}>
				<GetWorkDone {...getWorkDoneProps} />
			</Margins>
			<LogosAndG2Section/>
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>
		</PageLayout>
	);
}
